import React, { useState } from "react";
import "../css/Home.css";

const Home = () => {
    const [inputText, setInputText] = useState("");

    const [synopsysResponse, setSynopsysResponse] = useState('');
    const [propsResponse, setPropsResponse] = useState('');
    const [castMembersResponse, setCastMembersResponse] = useState('');
    const [specialEffectsResponse, setSpecialEffectsResponse] = useState('');
    const [stuntsResponse, setStuntsResponse] = useState('');

    const [synopsysLoading, setSynopsysLoading] = useState(false);
    const [propsLoading, setPropsLoading] = useState(false);
    const [castMembersLoading, setCastMembersLoading] = useState(false);
    const [specialEffectsLoading, setSpecialEffectsLoading] = useState(false);
    const [stuntsLoading, setStuntsLoading] = useState(false);

    // Function to make the POST request
    async function makePostRequest(url, title, systemPrompt, userPrompt, assistantPrompt, specialPrompt, textInput) {
        if(inputText.trim() !== "") {
            try {
                console.log("DAAAAA");
                // Set loading state to true
                if (title === 'Synopsys') setSynopsysLoading(true);
                if (title === 'Props') setPropsLoading(true);
                if (title === 'Cast Members') setCastMembersLoading(true);
                if (title === 'Special and Visual Effects') setSpecialEffectsLoading(true);
                if (title === 'Stunts') setStuntsLoading(true);

                const response = await fetch(url, { 
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 
                        text: textInput,
                        title: title,
                        systemPrompt: systemPrompt,
                        userPrompt: userPrompt,
                        assistantPrompt: assistantPrompt,
                        specialPrompt: specialPrompt
                    })
                });

                if (response.ok) {
                    const responseData = await response.text();
                    // Set loading state back to false
                    if (title === 'Synopsys') setSynopsysLoading(false);
                    if (title === 'Props') setPropsLoading(false);
                    if (title === 'Cast Members') setCastMembersLoading(false);
                    if (title === 'Special and Visual Effects') setSpecialEffectsLoading(false);
                    if (title === 'Stunts') setStuntsLoading(false);
                    return responseData; // Return the response data
                } else {
                    console.error('Failed to make POST request:', response.statusText);
                    // Set loading state back to false
                    if (title === 'Synopsys') setSynopsysLoading(false);
                    if (title === 'Props') setPropsLoading(false);
                    if (title === 'Cast Members') setCastMembersLoading(false);
                    if (title === 'Special and Visual Effects') setSpecialEffectsLoading(false);
                    if (title === 'Stunts') setStuntsLoading(false);
                    return null; // Return null if request failed
                }
            } catch (error) {
                console.error('Error making POST request:', error);
                // Set loading state back to false
                if (title === 'Synopsys') setSynopsysLoading(false);
                if (title === 'Props') setPropsLoading(false);
                if (title === 'Cast Members') setCastMembersLoading(false);
                if (title === 'Special and Visual Effects') setSpecialEffectsLoading(false);
                if (title === 'Stunts') setStuntsLoading(false);
                return null; // Return null if error occurred
            }
            
        }
        return null;
    }


    const handleButtonClick = async () => {
        if(inputText.trim() !== "") {
        const synopsysResponse = await makePostRequest(
        'https://hook.us1.make.com/lbjwhsj6n5zu76pgr7w7n1e4bvip2ln3', 
        'Synopsys', 
        'You are an AI movie production assistant specialized in analyzing movie scripts. For each scene provided extract: Scene Synopsis', 
        'Extract the Scene Synopsis in One Succint Sentence', 
        'The Scene Synopsis in One Concise Sentence is:', 
        inputText
        );
        console.log(synopsysResponse);
        setSynopsysResponse(synopsysResponse);

        const propsResponse = await makePostRequest(
        'https://hook.us1.make.com/lbjwhsj6n5zu76pgr7w7n1e4bvip2ln3', 
        'Props', 
        'You are an AI movie production assistant specialized in analyzing movie scripts. LIST OF PROPS', 
        'Extract the list of PROPS (innate or animated, small and large functional objects, quantity number for each):', 
        'The Complete List of Scene PROPS is:', 
        inputText
        );
        console.log(propsResponse);
        setPropsResponse(propsResponse);

        const castMembersResponse = await makePostRequest(
        'https://hook.us1.make.com/lbjwhsj6n5zu76pgr7w7n1e4bvip2ln3', 
        'Cast Members', 
        'You are an AI movie production assistant specialized in analyzing movie scripts. List of Cast Members', 
        'Extract the list of Cast Members that are in the Scene (main actors, background actors, secondary actors, etc)', 
        'The Complete List of Cast Members is:', 
        inputText
        );
        setCastMembersResponse(castMembersResponse);

        
        const specialEffectsResponse = await makePostRequest(
        'https://hook.us1.make.com/lbjwhsj6n5zu76pgr7w7n1e4bvip2ln3', 
        'Special and Visual Effects', 
        'You are an AI movie production assistant specialized in analyzing movie scripts. For each scene provided extract: Special and Visual Effects and their corresponding text in the script', 
        'Extract the list of Special and Visual Effects (that can be done on the set or with computers), if any', 
        'Special and Visual Effects, and their corresponding text in the script:', 
        'Print your initial answer then Review your answer and only present the obvious Special and Visual Effects, explain why:',
        inputText
        );
        console.log(specialEffectsResponse);
        setSpecialEffectsResponse(specialEffectsResponse);


        const stuntsResponse = await makePostRequest(
        'https://hook.us1.make.com/lbjwhsj6n5zu76pgr7w7n1e4bvip2ln3', 
        'Stunts', 
        'As an intern under a seasoned movie producer, your task is to extract stunt scenes from the screenplay of an upcoming action film. Review the script meticulously, identifying sequences needing daring stunts to heighten excitement. Analyze storyline, character dynamics, and key moments to pinpoint action-ready scenes. Provide detailed breakdowns including stunt nature, risks, and costs. Your sharp eye for detail and cinematic storytelling understanding will ensure the film\'s adrenaline-fueled spectacle aligns with budget constraints.', 
        'Extract the list of STUNT PERFORMANCES', 
        'DARING STUNT PERFORMANCES, and their corresponding text in the script:',
        'Print your initial answer then Review your answer and only present the obvious Special and Visual Effects, explain why:', 
        inputText
        );
        console.log(stuntsResponse);
        setStuntsResponse(stuntsResponse);
        }
    } 
    
    const handleChange = (e) => {
        setInputText(e.target.value);
        console.log(inputText)
    }

    const onClear = () => {
        setInputText("");
        setSynopsysResponse("");
        setPropsResponse("");
        setCastMembersResponse("");
        setSpecialEffectsResponse("");
        setStuntsResponse("");
    }

    return (
        <div className="noise">
            <div className="container">
                <div className="header">
                <h1>Scenario processing</h1>
                {/* <div class="center">
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                </div> */}
                </div>
                
                <div className="main-content">    
                    <div className="output-section">
                        <div id="output-1" className="output">
                            <h1 className="subtitle">Synopsis</h1>
                            {synopsysLoading ? (
                                <div className="loading-animation">
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                </div>
                            ) : (
                                synopsysResponse.split('\n').map((item, index) => (
                                    <div key={index} className="subtext">{item}</div>
                                ))
                            )}
                        </div>
                        <div id="output-2" className="output">
                            <h1 className="subtitle">Props</h1>

                            {propsLoading ? (
                                <div className="loading-animation">
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                </div>
                            ) : (
                                propsResponse.split('\n').map((item, index) => (
                                    <div key={index} className="subtext">{item}</div>
                                ))
                            )}                            
                        </div>
                        <div id="output-3" className="output">
                            <h1 className="subtitle">Cast Members</h1>
                            {castMembersLoading ? (
                                <div className="loading-animation">
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                </div>
                            ) : (
                                castMembersResponse.split('\n').map((item, index) => (
                                    <div key={index} className="subtext">{item}</div>
                                ))
                            )}
                        </div>
                        <div id="output-4" className="output">
                            <h1 className="subtitle">Special and Visual Effects</h1>

                            {specialEffectsLoading ? (
                                <div className="loading-animation">
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                </div>
                            ) : (
                                specialEffectsResponse.split('\n').map((item, index) => (
                                    <div key={index} className="subtext">{item}</div>
                                ))
                            )}
                        </div>
                        <div id="output-5" className="output">
                            <h1 className="subtitle">Stunts</h1>

                            {stuntsLoading ? (
                                <div className="loading-animation">
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                </div>
                            ) : (
                                stuntsResponse.split('\n').map((item, index) => (
                                    <div key={index} className="subtext">{item}</div>
                                ))
                            )}

                        </div>
                        
                    </div>
                    <div className="text-area">
                        <div className="box-area">
                        <textarea id="input-text" onChange={handleChange} value={inputText} placeholder="Enter text here..."></textarea>
                        <div className="button-container">
                            <button className="process-button" onClick={handleButtonClick}>Process Text</button>
                            <button className="clear-button" onClick={onClear}>Clear Text</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Home;